.scanner-api-dashboard-container {
    padding: 1rem;
  }
  
  /* Top row with stats boxes */
  .stats-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .stat-box {
    flex: 1;
    min-width: 180px;
    border-radius: 4px;
    color: #fff;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .stat-box .stat-value {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .stat-box .stat-label {
    font-size: 0.9rem;
    opacity: 0.8;
    margin-top: 0.5rem;
  }
  
  .stat-box.green {
    background-color: #76a142;
  }
  .stat-box.yellow {
    background-color: rgb(255, 193, 7);
    color: #000;
  }
  .stat-box.blue {
    background-color: #2980b9;
  }
  
  /* Middle row with date-range tabs */
  .time-range-tabs {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .time-range-tabs button {
    background: none;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: #666;
    font-weight: 500;
    border-bottom: 2px solid transparent;
  }
  .time-range-tabs button.active {
    color: #000;
    border-bottom-color: #76a142;
  }
  
  /* Bottom row for charts */
  .charts-row {
    display: flex;
    gap: 1rem;
  }
  
  /* Example containers for charts */
  .chart-container {
    flex: 1;
    min-height: 300px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .scanner-subscriptions-page {
    padding: 1rem;
  }
  
  /* Big heading */
  .page-heading {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  /* The green banner container */
  .subscriptions-banner {
    background-color: #28a745;
    color: #fff;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .banner-text {
    font-size: 1rem;
    font-weight: 500;
  }
  
  .banner-buttons button + button {
    margin-left: 0.5rem;
  }
  
  .table thead th {
    background: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
  }

  .scanner-payments-page {
    padding: 1rem;
  }
  
  /* Green header with title + subtitle */
  .payments-header {
    background-color: #28a745;
    color: #fff;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  .payments-header h2 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
  }
  
  .payments-header p {
    margin: 0.25rem 0 0;
    font-size: 0.95rem;
    opacity: 0.9;
  }
  
  .table thead th {
    background: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
  }
  
  .table tbody tr td {
    vertical-align: middle;
  }

  .scanner-api-settings-container {
    padding: 1rem;
  }
  
  .scanner-api-settings-container h2 {
    margin-bottom: 1rem;
  }
  
  .scanner-url-verification-container {
    padding: 1rem;
  }
  
  /* Gray header bar at top */
  .verification-header {
    background-color: #e9ecef;
    border-radius: 4px;
    padding: 1rem;
  }
  
  .verification-header h4 {
    margin: 0;
  }
  
  /* Spacing for cards */
  .mt-3 {
    margin-top: 1rem;
  }
  
  /* Row for input + button */
  .input-row {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  /* The colored banner (green or red) that appears at the top of the result card */
  .verification-status-banner {
    padding: 0.75rem 1rem;
    border-radius: 4px 4px 0 0; /* top corners only */
  }
  
  /* Table styling for the data in the card body */
  .verification-details {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0.5rem;
  }
  
  .verification-details tr {
    border-bottom: 1px solid #dee2e6;
  }
  
  .verification-details td {
    padding: 0.5rem 0.75rem;
  }
  
  .field-name {
    width: 30%;
    font-weight: 500;
  }
  .field-value {
    width: 70%;
  }
  